import { FC } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

import './styles.scss'
import { CONTACT_NUMBER } from 'constant'

interface Props {
  text: string
  show: boolean
  onClose: () => void
  whatsappButton?: boolean
  retryButton?: boolean
  retryAction?: () => void
  retryLoading?: boolean
}

export const Alert: FC<Props> = ({ text, show, onClose, whatsappButton, retryButton, retryAction, retryLoading }) => {

  return (
    <>
      {createPortal(
        <div className={classNames('alert', { show })}>
          <div className="content">
            <div className="box">
              <div className="text">{text}</div>
              {retryButton &&
                <div className="retry-button">
                  <button
                    onClick={retryAction}
                    disabled={retryLoading}
                  >
                    &nbsp;
                    {retryLoading ?
                      <img
                        className="loader"
                        src={require('assets/images/icons/loader.svg').default}
                        alt="loader"
                      />
                      :
                      <span>Coba Lagi</span>
                    }
                    &nbsp;
                  </button>
                </div>
              }
              {whatsappButton &&
                <div className="whatsapp-button">
                  <button
                    onClick={() => window.open(`https://wa.me/${CONTACT_NUMBER}`)}
                  >
                    <img
                      className="icon"
                      src={require('assets/images/icons/whatsapp.svg').default}
                      alt="icon"
                    />
                    WhatsApp CS
                  </button>
                </div>
              }
              <button
                onClick={onClose}
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>,
        document.body
      )}
    </>
  )
}
