import { FC } from 'react'

import './styles.scss'

export const NotFound: FC = () => {
  return (
    <div className="not-found-container">
      <div className="code">404</div>
      <div className="label">Page Not Found</div>
    </div>
  )
}
