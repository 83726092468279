import { STORAGE_ACCESS_KEY } from 'constant'

export const persistAccessToken = async (token: string): Promise<void> => {
  return localStorage.setItem(STORAGE_ACCESS_KEY, token)
}

export const destroyAccessToken = async (): Promise<void> => {
  return localStorage.removeItem(STORAGE_ACCESS_KEY)
}

export const getAccessToken = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_ACCESS_KEY)
}
