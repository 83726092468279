import { action, thunk, computed } from 'easy-peasy'

import {
  destroyAccessToken,
  setDefaultAuthJwt,
  getAccessToken,
  persistAccessToken
} from 'utils'
import { FetchStatus, AuthStatus } from 'types'

import { AuthModel, LoginDto, AuthService, RegisterDto } from '.'

export const auth: AuthModel = {
  status: FetchStatus.IDLE,
  authStatus: AuthStatus.LOADING,
  error: {},

  // Computed
  isAuthenticated: computed(
    state => state.authStatus === AuthStatus.AUTHENTICATED
  ),

  // Actions
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setAuthStatus: action((state, payload) => {
    state.authStatus = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  login: thunk(async (action, dto: LoginDto) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await AuthService.login(dto)
      const { data } = res
      action.loggedIn(data.access_token)
      action.setError({})
    } catch (e) {
      action.setError(e)
      action.setStatus(FetchStatus.ERROR)
    } finally {
      setTimeout(() => {
        action.setStatus(FetchStatus.IDLE)
        action.setError({})
      }, 10)
    }
  }),
  register: thunk(async (action, dto: RegisterDto) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await AuthService.register(dto)
      const { data } = res
      action.loggedIn(data.api_token)
      action.setStatus(FetchStatus.LOADED)
    } catch (e) {
      action.setError(e)
      action.setStatus(FetchStatus.ERROR)
    } finally {
      setTimeout(() => {
        action.setStatus(FetchStatus.IDLE)
        action.setError({})
      }, 10)
    }
  }),
  checkAuth: thunk(async (action) => {
    action.setAuthStatus(AuthStatus.LOADING)
    const accessToken = await getAccessToken()
    if (!accessToken) action.setAuthStatus(AuthStatus.UNAUTHENTICATED)
    else action.loggedIn(accessToken)
  }),
  loggedIn: thunk(async (action, payload) => {
    await Promise.all([
      persistAccessToken(payload),
      setDefaultAuthJwt(payload)
    ])
    action.setAuthStatus(AuthStatus.AUTHENTICATED)
  }),
  loggedOut: thunk(async (action, callback) => {
    await Promise.all([destroyAccessToken()])
    setDefaultAuthJwt('')
    action.setStatus(FetchStatus.IDLE)
    action.setAuthStatus(AuthStatus.UNAUTHENTICATED)
    callback()
  })
}
