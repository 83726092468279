import { action, thunk, thunkOn } from 'easy-peasy'
import { AxiosError } from 'axios'

import { AuthStatus, FetchStatus } from 'types'

import { Profile, ProfileModel, ProfileService } from '.'

export const profile: ProfileModel = {
  profile: {} as Profile,
  status: FetchStatus.LOADING,
  error: {},

  // Actions
  setProfile: action((state, payload) => {
    state.profile = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  clearState: action(state => {
    state.profile = {} as Profile
    state.status = FetchStatus.LOADING
    state.error = {}
  }),

  // Thunks
  fetchProfile: thunk(async (action) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await ProfileService.fetchProfile()
      action.setProfile(res.data)
      action.setStatus(FetchStatus.LOADED)
    } catch (e) {
      if (e instanceof AxiosError) {
        action.clearState()
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.ERROR)
      }
    } finally {
      setTimeout(() => {
        action.setStatus(FetchStatus.IDLE)
        action.setError({})
      }, 10)
    }
  }),
  refreshProfile: thunk(async (action) => {
    try {
      action.setStatus(FetchStatus.REFRESHING)
      const res = await ProfileService.fetchProfile()
      action.setProfile(res.data)
    } catch (e) {
      if (e instanceof AxiosError) {
        action.clearState()
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.ERROR)
      }
    } finally {
      setTimeout(() => {
        action.setStatus(FetchStatus.IDLE)
        action.setError({})
      }, 10)
    }
  }),

  // Thunks On
  onLoggedOut: thunkOn(
    (_, storeAction) => storeAction.auth.setAuthStatus,
    (action, target) => {
      if (target.payload === AuthStatus.UNAUTHENTICATED) {
        action.clearState()
      }
    }
  )
}