import { api } from 'utils'

import { EarningDto } from '.'

interface EarningResponse {
  status: string
  status_code: number
  data: any
}

const earning = async (dto: EarningDto): Promise<EarningResponse> => {
  try {
    const body = {
      ...dto
    }

    const res = await api.post<EarningResponse>('earning/coupun-code', body)
    const { data } = res

    return data
  } catch (e) {
    throw e
  }
}

export const earningService = {
  earning
}
