import { FC, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { Container } from 'components'
import { Account, Home, Luckydraw, NotFound } from 'pages'
import { useStoreActions } from 'stores'

export const App: FC = () => {
  const { checkAuth } = useStoreActions((action) => action.auth)

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  return (
    <Container>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/account' element={<Account />} />
        <Route path='/lucky-number' element={<Luckydraw />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Container>
  )
}
