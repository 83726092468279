import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCFdmACgjfBIYRdDtnoG3BaB6R6CatkWEc",
  authDomain: "macs-hybrid.firebaseapp.com",
  projectId: "macs-hybrid",
  storageBucket: "macs-hybrid.appspot.com",
  messagingSenderId: "199417976517",
  appId: "1:199417976517:web:2bf2bc95c3ed2e32f482ac",
  measurementId: "G-SMK7RHE08L"
}

const firebaseApp = initializeApp(firebaseConfig)
export const db = getFirestore(firebaseApp)
