import { FC, ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import './styles.scss'

interface Props {
  children: ReactNode
}

export const Container: FC<Props> = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    const color = darkMode ? '#ffffff' : '#004479'

    const loyalid = `
    <svg width="299px" height="95px" viewBox="0 0 299 95" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>loyalid</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M23,63 L23.0274702,62.999947 C23.0093187,63.1641309 23,63.3309772 23,63.5 C23,65.9852814 25.0147186,68 27.5,68 C29.359189,68 30.95504,66.8725161 31.6411029,65.2639985 C31.875444,66.1380165 32,67.0550949 32,68 C32,74.0751322 26.8512746,79 20.5,79 C14.247964,79 9.16119126,74.227832 9.00375599,68.2839149 L9,68 L9,18 L8.97242641,18.0009856 C8.99064601,17.8365009 9,17.6693428 9,17.5 C9,15.0147186 6.98528137,13 4.5,13 C2.64081102,13 1.04496,14.1274839 0.358897139,15.7360015 C0.124544731,14.8619005 0,13.9448624 0,13 C0,6.92486775 5.14872538,2 11.5,2 C17.752036,2 22.8388087,6.772168 22.996244,12.7160851 L23,13 L23,63 Z" id="L" fill="${color}"></path>
            <path d="M59.5,25 C67.2998594,25 74.281796,28.7766059 78.9592944,34.7267127 L71.2180227,30.8678017 L68.0177818,39.9062941 C65.9139111,36.2782324 62.876935,34 59.5,34 C53.1487254,34 48,42.0588745 48,52 C48,61.9411255 53.1487254,70 59.5,70 C65.8512746,70 71,61.9411255 71,52 C71,50.7921159 70.9239885,49.6120197 70.7790441,48.4707911 L66.4252941,50.2291676 L72.2180227,33.8678017 L87.7505228,41.613216 L83.5426583,43.3140024 C84.4863879,46.1866036 85,49.2786172 85,52.5 C85,67.6878306 73.5832611,80 59.5,80 C45.4167389,80 34,67.6878306 34,52.5 C34,37.3121694 45.4167389,25 59.5,25 Z" id="O" fill="${color}"></path>
            <path d="M139.51525,26.416699 C139.979911,26.6235791 140.424678,26.8561768 140.848451,27.1118894 L131.530626,48.0383495 L131.531629,48.0377015 L114.04095,87.3208042 L114.02969,87.3159943 C113.969751,87.4706124 113.905731,87.6245121 113.837582,87.7775769 C111.47892,93.0752178 105.067757,95.3666801 99.5178471,92.8957012 C94.1337652,90.4985534 91.538162,84.4999379 93.5407542,79.3070533 C93.8848289,80.7788739 94.8896175,82.0779904 96.376922,82.740181 C98.8295335,83.832154 101.691297,82.7901104 102.880542,80.4131288 L102.978333,80.2061369 L106.162307,73.0560154 L106.162481,73.056106 L106.496375,72.3057745 L88.9586115,32.9148134 L88.8285517,32.7664737 C88.7835947,32.6472597 88.734897,32.5286362 88.6824062,32.4107399 C87.9523442,30.7709939 86.6267588,29.5823986 85.0737524,28.9862553 L85.0737524,28.9862553 L84.9443749,28.9388067 L85.0617162,28.8237413 C85.9899455,27.9311129 87.0920772,27.1780304 88.3452826,26.6200674 C93.8951921,24.1490885 100.306356,26.4405508 102.665017,31.7381917 C102.733166,31.8912565 102.797186,32.0451561 102.857126,32.1997743 L102.857126,32.1997743 L102.868386,32.1949643 L113.611375,56.3247745 L124.535375,31.7882277 C127.006354,26.2383182 133.713072,23.8334029 139.51525,26.416699 Z" id="Y" fill="${color}"></path>
            <path d="M164.367001,23.9994465 C174.832601,23.9994465 184,31.7882276 184,42.2568599 L184,58.9999997 C184,71.5710431 174.126042,79.5299224 161.357152,79.5299224 C148.588262,79.5299224 140.734001,72.8445132 140.734001,64.4999997 C140.734001,63.6472344 140.754889,62.7932868 140.792395,61.940259 C141.96536,50.2959748 155.650905,48.4296942 160.877957,47.2582286 C166.341251,46.0338173 173,43.4942711 173,39.0327962 C173,34 159.016984,31 154,39.4032554 C152.203665,41.9379476 148.447311,42.6254288 142.730937,41.4656993 L142.734001,41.0963582 L142.734001,41.0963582 C142.734001,34.4949477 148.818869,23.9980509 164.367001,23.9994465 Z M171.905687,53.1828459 C168.065013,56.2189822 163.927114,57 160.229048,57.8472501 C155.63511,58.8997502 152.649004,61.1316591 153.543467,64.9809329 C154.437931,68.8302066 158.006149,69.8302066 162.642773,69.0958374 C167.279397,68.3614683 171.831694,64.6786193 171.87282,58.2890868 C171.891601,55.3712349 171.89965,54.1207269 171.903099,53.5847949 L171.903771,53.4805425 C171.903871,53.4648743 171.903967,53.4500307 171.904057,53.4359683 L171.90492,53.3019419 C171.904975,53.293435 171.905026,53.2855358 171.905073,53.2782008 L171.905687,53.1828459 Z" id="A" fill="${color}"></path>
            <g id="L" transform="translate(191.947860, 3.000000)">
                <path d="M2.55213956,0 C8.80417552,0 13.8909483,4.772168 14.0483836,10.7160851 L14.0521396,11 L14.0527264,48 L14.0521396,48 L14.0521396,61 L14.0685208,61.0003246 C14.057657,61.16548 14.0521396,61.3320956 14.0521396,61.5 C14.0521396,65.6421356 17.4100039,69 21.5521396,69 C21.9221343,69 22.2858713,68.973208 22.6415166,68.9214582 C21.304833,73.5781578 16.8462573,77 11.5521396,77 C5.30010361,77 0.213330824,72.227832 0.0558955503,66.2839149 L0.0521395647,66 L0.0510704135,0.260924697 C0.856217851,0.0900776491 1.69318117,0 2.55213956,0 Z" fill="${color}"></path>
                <circle id="Oval" fill="#D30000" cx="22.0521396" cy="61" r="6"></circle>
            </g>
            <g id="I" transform="translate(222.998932, 0.032796)">
                <path d="M14.0010679,63.9672036 L14.0285381,63.9671506 C14.0103866,64.1313344 14.0010679,64.2981808 14.0010679,64.4672036 C14.0010679,66.952485 16.0157865,68.9672036 18.5010679,68.9672036 C20.3602569,68.9672036 21.9561079,67.8397197 22.6421708,66.2312021 C22.8765119,67.1052201 23.0010679,68.0222985 23.0010679,68.9672036 C23.0010679,75.0423358 17.8523425,79.9672036 11.5010679,79.9672036 C5.24903194,79.9672036 0.162259158,75.1950356 0.00482388457,69.2511185 L0.00106789894,68.9672036 L0.00106789894,32.9672036 L0.0338615986,32.9673685 C0.526628323,25.4795872 6.51381825,19.4925291 14.0016561,18.9999694 L14.0010679,63.9672036 Z" id="Combined-Shape" fill="${color}"></path>
                <path d="M14.0011917,-2.9760916e-14 L14.0010679,16.9672036 L14.0010679,16.9672036 C7.61803006,16.9672036 2.16619124,20.9541405 1.9817481e-14,26.5735658 L0.00106789894,14.9672036 L0.00428565763,14.6533754 C0.164475659,6.84833259 6.28624784,0.507737273 14.0011917,-2.99413272e-14 Z" id="Combined-Shape" fill="#D30000"></path>
            </g>
            <path d="M298,54 L297.962668,54.0008554 C297.240012,68.4907296 286.116323,80 272.5,80 C258.416739,80 247,67.6878306 247,52.5 C247,37.3121694 258.416739,25 272.5,25 C276.637758,25 280.545336,26.0628166 284.000808,27.9491199 L284,13 L284.010665,13.0002062 C284.272339,6.88080153 289.316183,2 295.5,2 C296.358799,2 297.195611,2.09413712 298.00068,2.27265545 L298,54 Z M272.5,34 C266.148725,34 261,42.0588745 261,52 C261,61.9411255 266.148725,70 272.5,70 C278.851275,70 284,61.9411255 284,52 C284,42.0588745 278.851275,34 272.5,34 Z" id="D" fill="${color}"></path>
        </g>
    </svg>
  `
    const logo = `data:image/svg+xml;base64,${btoa(loyalid)}`

    console.clear()
    console.info(
      `%c %c\nCopyright © ${new Date().getFullYear()} by loyal.id `,
      `
        background: url(${logo});
        padding-left: 90px;
        padding-bottom: 20px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      `,
      `
        color: ${color};
        border-radius: 4px;
        font-size: 10px;
      `
    )
  }, [])

  useEffect(() => {
    const handleContextmenu = (e: MouseEvent) => {
      if (process.env.NODE_ENV === 'production') {
        e.preventDefault()
      } else {
        return false
      }
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  return (
    <div className="macs-hybrid-app-v2.0.1">
      {children}
    </div>
  )
}
