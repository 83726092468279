import { createStore, createTypedHooks, EasyPeasyConfig } from 'easy-peasy'

import { AuthModel, auth } from './auth'
import { ProfileModel, profile } from './profile'
import { LuckyDrawModel, luckydraw } from './luckydraw'
import { EarningModel, earning } from './earning'
import { VoucherModel, voucher } from './voucher'
import { RedeemModel, redeem } from './redeem'

export interface StoreModel {
  auth: AuthModel,
  profile: ProfileModel,
  luckydraw: LuckyDrawModel,
  earning: EarningModel,
  voucher: VoucherModel,
  redeem: RedeemModel
}

const storeModel: StoreModel = {
  auth,
  profile,
  luckydraw,
  earning,
  voucher,
  redeem
}

const storeConfig: EasyPeasyConfig = {
  devTools: process.env.NODE_ENV !== 'production'
}

const store = createStore(storeModel, storeConfig)
const typedHooks = createTypedHooks<StoreModel>()

export const { useStoreActions, useStoreDispatch, useStoreState } = typedHooks
export default store
