import { action, thunk, computed } from 'easy-peasy'
import { AxiosError } from 'axios'

import { FetchStatus } from 'types'
import { isEmpty } from 'utils'

import { LuckyDrawModel, fetchLuckyDraws } from '.'

export const luckydraw: LuckyDrawModel = {
  isEmpty: computed(
    state => isEmpty(state.data) && state.status === FetchStatus.LOADED,
  ),
  paginator: {
    current_page: 1,
    next_page: 1,
    per_page: 15,
    previous_page: 1,
    total_items: 0,
    total_pages: 1,
  },
  data: [],
  status: FetchStatus.LOADING,
  error: '',

  // Actions
  setPaginator: action((state, payload) => {
    state.paginator = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),

  addData: action((state, payload) => {
    state.data = [...state.data, ...payload]
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  fetchLuckyDraws: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await fetchLuckyDraws(payload)
      action.setPaginator(res.paginator)
      action.setData(res.data)
      action.setStatus(FetchStatus.LOADED)
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.ERROR)
      }
    } finally {
      setTimeout(() => {
        action.setError('')
      }, 10)
    }
  }),
  fetchMoreLuckyDraws: thunk(async (action, payload) => {
    try {
      if (payload.paginator.page > 0) {
        action.setStatus(FetchStatus.FETCHING_MORE)
        const res = await fetchLuckyDraws(payload)
        action.setPaginator(res.paginator)
        action.addData(res.data)
        action.setError('')
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
        action.setStatus(FetchStatus.FETCHING_MORE_ERROR)
      }
    } finally {
      action.setStatus(FetchStatus.LOADED)
    }
  })
}