import { api, getAccessToken } from 'utils'
import { CLIENT_CODE } from 'constant'

import { Profile } from '.'

interface ProfileResponse {
  status: string
  status_code: number
  data: Profile
}

const fetchProfile = async (): Promise<ProfileResponse> => {
  try {
    const accessToken = await getAccessToken()
    const res = await api.get<ProfileResponse>(`customer/profile?code_client=${CLIENT_CODE}&access_token=${accessToken}`)
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const ProfileService = {
  fetchProfile
}
