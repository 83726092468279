import { PaginationData } from 'types'
import { api, getAccessToken } from 'utils'
import { CLIENT_CODE } from 'constant'

import { GetLuckyDrawsParams } from '.'

export const fetchLuckyDraws = async (
  params: GetLuckyDrawsParams,
): Promise<PaginationData<string>> => {
  try {
    const accessToken = await getAccessToken()
    const res = await api.get<PaginationData<string>>(
      `customer/lucky-draw?code_client=${CLIENT_CODE}&access_token=${accessToken}`,
      {
        params: {
          limit: params.paginator.limit,
          page: params.paginator.page
        }
      }
    )
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const luckyDrawService = {
  fetchLuckyDraws
}