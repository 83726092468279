import { FC, useState } from 'react'
import InputMask from 'react-input-mask'
import { useFormik } from 'formik'
import * as yup from 'yup'
import JSEncrypt from 'jsencrypt'

import { useStoreActions, useStoreState } from 'stores'
import { isEmpty } from 'utils'
import { FetchStatus } from 'types'
import { PUBLIC_KEY } from 'constant'

interface Props {
  phone: string
  setPhone: (phone: string) => void
}

export const LoginForm: FC<Props> = ({ phone, setPhone }) => {
  const { status } = useStoreState(state => state.auth)
  const { login } = useStoreActions(action => action.auth)

  const [validate, setValidate] = useState<boolean>(false)

  const loading = status === FetchStatus.LOADING

  const SignInSchema = yup.object().shape({
    phone: yup.string().required('Nomor Handphone harus diisi').matches(/\+62 8/, 'Nomor Handphone tidak valid').min(16, 'Nomor Handphone kurang dari 11 digit').matches(/\+62 8?\d{2}?[-]\d{4}[-]\d{2,4}?[-]?\d{1,2}/, 'Nomor Handphone tidak valid')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      phone: phone || '',
      password: ''
    },
    validationSchema: SignInSchema,
    validateOnChange: validate,
    onSubmit: values => {
      const phone = values.phone.replace(/[+-\s]/g, '')
      const encrypt = new JSEncrypt()
      encrypt.setPublicKey(PUBLIC_KEY)
      const username = encrypt.encrypt(`${phone},${Math.floor(Date.now() / 1000)}`).toString()
      login({ username, password: values.password })
    }
  })

  return (
    <form onSubmit={handleSubmit} className="form">
      <div className="field">
        <div className="label">Nomor Handphone</div>
        <div className="input-wrapper">
          <InputMask
            type="tel"
            mask="+62 999-9999-9999-99"
            maskPlaceholder={null}
            name="phone"
            onChange={e => {
              let phone = e.target.value
              handleChange('phone')(phone)
              setPhone(phone)
            }}
            beforeMaskedStateChange={({ nextState, currentState, previousState }) => {
              let { value } = nextState
              if (value.endsWith('-')) value = value.slice(0, -1)
              if (value.charAt(4) === '0') value = `${value.slice(0, 4)}${value.slice(5)}`
              if (value.slice(4, 6) === '62') value = `${value.slice(0, 4)}${value.slice(6)}`
              if (currentState?.value?.startsWith('0')) {
                const phone = `+62${currentState?.value.slice(1)}`
                value = `${phone.slice(0, 3)} ${phone.slice(3, 6)}-${phone.slice(6, 10)}-${phone.slice(10, 14)}${phone.length > 14 ? `-${phone.slice(14, 16)}` : ''}`
                nextState.selection!.start = value.length
                nextState.selection!.end = value.length
              }
              if (currentState?.value?.slice(0, 2) === '62') {
                const phone = `+${currentState?.value}`
                value = `${phone.slice(0, 3)} ${phone.slice(3, 6)}-${phone.slice(6, 10)}-${phone.slice(10, 14)}${phone.length > 14 ? `-${phone.slice(14, 16)}` : ''}`
                nextState.selection!.start = value.length
                nextState.selection!.end = value.length
              }

              return {
                ...nextState,
                value
              }
            }}
            value={values.phone}
            autoFocus={!isEmpty(phone)}
            autoCorrect="off"
            spellCheck="false"
          />
          {loading &&
            <img
              className="loader"
              src={require('assets/images/icons/loader.svg').default}
              alt="loader"
            />
          }
        </div>
        {errors.phone &&
          <div className="error">*{errors.phone}</div>
        }
      </div>
      <button
        type="submit"
        disabled={loading || !values.phone}
        onClick={() => setValidate(true)}
      >
        LANJUT
      </button>
    </form>
  )
}
