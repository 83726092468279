import { api, authApi } from 'utils'
import { CLIENT_CODE } from 'constant'

import { AuthResponse, LoginDto, RegisterDto, RegisterResponse } from '.'

const login = async (dto: LoginDto): Promise<AuthResponse> => {
  try {
    const body = {
      client_code: CLIENT_CODE,
      username: dto.username,
      password: ''
    }
    const res = await authApi.post<AuthResponse>('auth', body)
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

const register = async (dto: RegisterDto): Promise<RegisterResponse> => {
  try {
    const body = {
      client_code: CLIENT_CODE,
      ...dto
    }
    const res = await api.post<RegisterResponse>('register/new', body)
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const AuthService = {
  login,
  register
}
