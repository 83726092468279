import axios from 'axios'
import { getAuth } from 'firebase/auth'

import { BASE_URL, AUTH_URL } from 'constant'
import store from 'stores'

export const api = axios.create({
  baseURL: BASE_URL
})

export const authApi = axios.create({
  baseURL: AUTH_URL
})

export const setDefaultAuthJwt = (jwt: string): void => {
  api.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
  api.interceptors.response.use(response => {
    return response
  }, error => {
    const auth = getAuth()
    const user = auth.currentUser
    if (error?.response?.status === 401)
      store.dispatch.auth.loggedOut(() => user?.delete().catch(error => console.log(error)))
    return Promise.reject(error)
  })
  authApi.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
}
