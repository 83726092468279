import { FC, useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useStoreActions, useStoreState } from 'stores'
import { FetchStatus } from 'types'

interface Props {
  phone: string
  setPhone: (phone: string) => void
}

export const RegisterForm: FC<Props> = ({ phone, setPhone }) => {
  const { status } = useStoreState(state => state.auth)
  const { register } = useStoreActions(action => action.auth)

  const [validate, setValidate] = useState<boolean>(false)

  const loading = status === FetchStatus.LOADING

  const SignUpSchema = yup.object().shape({
    phone: yup.string().required('Nomor Handphone harus diisi').matches(/\+62 8/, 'Nomor Handphone tidak valid').min(16, 'Nomor Handphone kurang dari 11 digit').matches(/\+62 8?\d{2}?[-]\d{4}[-]\d{2,4}?[-]?\d{1,2}/, 'Nomor Handphone tidak valid'),
    name: yup.string().required('Nama harus diisi'),
    ktp: yup.string().required('Nomor KTP harus diisi').min(19, 'Nomor KTP kurang dari 16 digit')
  })

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      phone: phone || '',
      name: '',
      ktp: ''
    },
    validationSchema: SignUpSchema,
    validateOnChange: validate,
    onSubmit: values => {
      register({
        name: values.name,
        preferred_contact: 'phone',
        mobile_phone: values.phone.replace(/[+-\s]/g, ''),
        id_number: values.ktp.replace(/[\s]/g, ''),
        password: Math.random().toString(36).slice(-10)
      })
    }
  })

  useEffect(() => {
    const el = document.getElementsByClassName('authentication')[0]
    el.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return (
    <form onSubmit={handleSubmit} className="form">
      <div className="field">
        <div className="label">Nomor Handphone</div>
        <InputMask
          type="tel"
          mask="+62 999-9999-9999-99"
          maskPlaceholder={null}
          name="phone"
          onChange={e => {
            let phone = e.target.value
            handleChange('phone')(phone)
            setPhone(phone)
          }}
          beforeMaskedStateChange={({ nextState, currentState, previousState }) => {
            let { value } = nextState
            if (value.endsWith('-')) value = value.slice(0, -1)
            if (value.charAt(4) === '0') value = `${value.slice(0, 4)}${value.slice(5)}`
            if (value.slice(4, 6) === '62') value = `${value.slice(0, 4)}${value.slice(6)}`
            if (currentState?.value?.startsWith('0')) {
              const phone = `+62${currentState?.value.slice(1)}`
              value = `${phone.slice(0, 3)} ${phone.slice(3, 6)}-${phone.slice(6, 10)}-${phone.slice(10, 14)}${phone.length > 14 ? `-${phone.slice(14, 16)}` : ''}`
              nextState.selection!.start = value.length
              nextState.selection!.end = value.length
            }
            if (currentState?.value?.slice(0, 2) === '62') {
              const phone = `+${currentState?.value}`
              value = `${phone.slice(0, 3)} ${phone.slice(3, 6)}-${phone.slice(6, 10)}-${phone.slice(10, 14)}${phone.length > 14 ? `-${phone.slice(14, 16)}` : ''}`
              nextState.selection!.start = value.length
              nextState.selection!.end = value.length
            }

            return {
              ...nextState,
              value
            }
          }}
          value={phone}
          autoCorrect="off"
          spellCheck="false"
        />
        {errors.phone &&
          <div className="error">*{errors.phone}</div>
        }
      </div>
      <div className="field">
        <div className="label">Nama (sesuai KTP)</div>
        <input
          name="name"
          onChange={handleChange}
          value={values.name}
          autoFocus
          autoCorrect="off"
          spellCheck="false"
          autoComplete="off"
        />
        {errors.name &&
          <div className="error">*{errors.name}</div>
        }
      </div>
      <div className="field">
        <div className="label">Nomor KTP</div>
        <InputMask
          type="tel"
          mask="9999 9999 9999 9999"
          maskPlaceholder={null}
          name="ktp"
          onChange={handleChange}
          value={values.ktp}
          autoCorrect="off"
          spellCheck="false"
          autoComplete="off"
        />
        {errors.ktp &&
          <div className="error">*{errors.ktp}</div>
        }
      </div>
      <button
        type="submit"
        disabled={loading}
        onClick={() => setValidate(true)}
      >
        &nbsp;
        {loading ?
          <img
            className="loader"
            src={require('assets/images/icons/loader.svg').default}
            alt="loader"
          />
          :
          <span>LANJUT</span>
        }
        &nbsp;
      </button>
    </form>
  )
}
