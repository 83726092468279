import { action, thunk } from 'easy-peasy'

import { FormStatus } from 'types'

import { EarningModel, earningService } from '.'

export const earning: EarningModel = {
  data: null,
  status: FormStatus.IDLE,
  error: '',

  // Actions
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  earning: thunk(async (action, payload) => {
    try {
      action.setStatus(FormStatus.LOADING)
      const res = await earningService.earning(payload)
      action.setStatus(FormStatus.SUCCESS)
      action.setData(res.data)
    } catch (e: any) {
      action.setStatus(FormStatus.ERROR)
      action.setError(e?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
    } finally {
      setTimeout(() => {
        action.setError('')
        action.setStatus(FormStatus.IDLE)
      }, 10)
    }
  })
}
