import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Marquee from 'react-fast-marquee'
import classNames from 'classnames'

import { Alert } from 'components'
import { useStoreState } from 'stores'

import { LoginForm, RegisterForm } from './forms'

import './styles.scss'
import { FetchStatus } from 'types'

export const Home: FC = () => {
  const navigate = useNavigate()

  const [phone, setPhone] = useState<string>('')
  const [register, setRegister] = useState<boolean>(false)
  const [start, setStart] = useState<boolean>(false)
  const [showLogo, setShowLogo] = useState<boolean>(false)
  const [penLine, setPenLine] = useState<boolean>(false)
  const [showPen, setShowPen] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [playText, setPlaytext] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const { isAuthenticated, error: authError, status } = useStoreState(state => state.auth)

  useEffect(() => {
    setTimeout(() => {
      setShowLogo(true)
    }, 500)
    setTimeout(() => {
      setStart(true)
    }, 1500)
    setTimeout(() => {
      setPenLine(true)
    }, 1500)
    setTimeout(() => {
      setShowPen(true)
      setShowForm(true)
    }, 4000)
    setTimeout(() => {
      setPlaytext(true)
    }, 7000)
  }, [])

  useEffect(() => {
    if (isAuthenticated) navigate('/account')
  }, [isAuthenticated, navigate])

  useEffect(() => {
    if (authError?.response?.data?.status_code === 400) {
      setRegister(true)
    }
  }, [status, authError])

  useEffect(() => {
    if (status === FetchStatus.ERROR && authError?.response?.data?.status_code !== 400) {
      setShowAlert(true)
      setError(authError?.response?.data?.message || (navigator.onLine ? 'Server Error' : 'Network Error'))
    }
  }, [status, authError])

  useEffect(() => {
    if (showAlert) window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [showAlert])

  return (
    <div className={classNames('home-container', { start })}>
      <div className={classNames('logo', { show: showLogo })}>
        <img
          className="macs"
          src={require('assets/images/logo/macs.png')}
          alt="macs"
        />
        <img
          className="macs-hybrid-gel"
          src={require('assets/images/logo/macs-hybrid-gel.png')}
          alt="macs-hybrid-gel"
        />
        {/* <div className="hybrid-gel">HYBRID GEL</div> */}
      </div>
      <div className={classNames('banner', { start: penLine })}>
        <div className={classNames('pen-container', { show: showPen })}>
          <img
            className="pen-box"
            src={require('assets/images/banner/pen-box.png')}
            alt="pen-box"
          />
          <img
            className="pen"
            src={require('assets/images/banner/pen.png')}
            alt="pen"
          />
          <div className="line" />
        </div>
        <div className={classNames('grand-prize', { show: showPen })}>
          <div className="title">GRAND PRIZE</div>
          <img
            className="kijang-innova"
            src={require('assets/images/banner/kijang-innova.png')}
            alt="kijang-innova"
          />
          <div className="sprites-animation" />
        </div>
        <div className={classNames('info', { show: showForm })}>
          <Marquee
            play={playText}
            speed={70}
            pauseOnClick
          >
            <div className="step">
              <div className="number">1</div>
              <span>Temukan Kode Unik di dalam kemasan <strong>MACS HYBRID GEL</strong></span>
            </div>
            <div className="step">
              <div className="number">2</div>
              <span>Kumpulkan huruf <strong>"MACS HYBRID"</strong> & Dapatkan <strong>E-Voucher Shopee</strong></span>
            </div>
            <div className="step">
              <div className="number">3</div>
              <span>Menangkan Grand Prize <strong>KIJANG INNOVA ZENIX HYBRID</strong></span>
            </div>
          </Marquee>
        </div>
      </div>
      <div className={classNames('authentication', { show: showForm })}>
        {register ?
          <RegisterForm
            phone={phone}
            setPhone={phone => {
              setPhone(phone)
              setRegister(false)
            }}
          />
          :
          <LoginForm
            phone={phone}
            setPhone={setPhone}
          />
        }
      </div>
      <div className={classNames('powered', { show: showLogo })}>
        <div
          className="button"
          onClick={() => window.open('https://loyal.id')}
        >
          <div className="label">Powered by</div>
          <img
            className="logo-loyalid"
            src={require('assets/images/logo/loyalid-white.svg').default}
            alt="logo-loyalid"
          />
        </div>
      </div>
      <Alert
        text={error}
        show={showAlert}
        onClose={() => {
          setShowAlert(false)
          setTimeout(() => setError(''), 1000)
        }}
      />
    </div>
  )
}
