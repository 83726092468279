import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'

import stores from 'stores'

import { App } from 'components'
import './assets/fonts/ethnocentric.ttf'
import './styles.scss'

import reportWebVitals from './reportWebVitals'

if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Router>
      <StoreProvider store={stores}>
        <App />
      </StoreProvider>
    </Router>
  </React.StrictMode>
)

reportWebVitals()
