import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import { useStoreActions, useStoreState } from 'stores'
import { AuthStatus, FetchStatus } from 'types'

import './styles.scss'
import { isEmpty } from 'utils'
import { Alert } from 'components'

export const Luckydraw: FC = () => {
  const navigate = useNavigate()

  const { isAuthenticated, authStatus } = useStoreState(state => state.auth)
  const { fetchLuckyDraws, fetchMoreLuckyDraws } = useStoreActions(action => action.luckydraw)
  const { data, status, paginator, error: luckdrawError } = useStoreState(state => state.luckydraw)

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const limit = 50

  const getData = useCallback(() => {
    fetchLuckyDraws({
      paginator: {
        page: 1,
        limit
      }
    })
  }, [fetchLuckyDraws])

  useEffect(() => {
    if (authStatus !== AuthStatus.LOADING) {
      if (isAuthenticated) getData()
      else navigate('/')
    }
  }, [isAuthenticated, authStatus, navigate, getData])

  const fetchMore = useCallback(() => {
    if (
      status === FetchStatus.LOADED &&
      paginator.current_page < paginator.total_pages
    ) {
      fetchMoreLuckyDraws({
        paginator: {
          page: paginator.current_page + 1,
          limit
        }
      })
    }
  }, [
    fetchMoreLuckyDraws,
    paginator.current_page,
    paginator.total_pages,
    status
  ])

  useEffect(() => {
    if (status === FetchStatus.ERROR && !isEmpty(luckdrawError)) {
      setError(luckdrawError)
      setShowAlert(true)
    }
  }, [status, luckdrawError])

  useEffect(() => {
    if (showAlert) window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [showAlert])

  return (
    <div className="luckydraw-container">
      <div className="back">
        <div
          className="button"
          onClick={() => navigate('/account', { replace: true })}
        >
          <img
            className="icon"
            src={require('assets/images/icons/back.svg').default}
            alt="icon"
          />
        </div>
      </div>
      <div className="logo">
        <img
          className="macs"
          src={require('assets/images/logo/macs.png')}
          alt="macs"
        />
        <img
          className="macs-hybrid-gel"
          src={require('assets/images/logo/macs-hybrid-gel.png')}
          alt="macs-hybrid-gel"
        />
        {/* <div className="hybrid-gel">HYBRID GEL</div> */}
      </div>

      <div className="title">
        Lucky Number
      </div>

      {status === FetchStatus.LOADING && data?.length === 0 &&
        <img
          className="loader"
          src={require('assets/images/icons/loader-dots.svg').default}
          alt="loader"
        />
      }

      <InfiniteScroll
        className="lucky-number"
        dataLength={data?.length}
        next={fetchMore}
        hasMore={paginator.next_page > 1}
        loader={
          <img
            className="loader"
            src={require('assets/images/icons/loader-dots.svg').default}
            alt="loader"
          />
        }
        endMessage={
          status !== FetchStatus.LOADING &&
          <div className="complete" />
        }
      >
        {
          data.map((data, index) => (
            <div
              className="card"
              key={index}
            >
              {data}
            </div>
          ))
        }
      </InfiniteScroll>
      <Alert
        text={error}
        show={showAlert}
        onClose={() => {
          setShowAlert(false)
          setTimeout(() => setError(''), 1000)
        }}
      />
    </div >
  )
}
