import { CLIENT_CODE } from 'constant'
import { api, getAccessToken } from 'utils'

import { RedeemDto, Redeem } from '.'

interface RedeemResponse {
  status: string
  status_code: number
  data: Redeem
}

const redeem = async (dto: RedeemDto): Promise<RedeemResponse> => {
  try {
    const body = {
      ...dto
    }

    const accessToken = await getAccessToken()
    const res = await api.post<RedeemResponse>(`redeem/voucher?code_client=${CLIENT_CODE}&access_token=${accessToken}`, body)
    const { data } = res

    return data
  } catch (e) {
    throw e
  }
}

export const redeemService = {
  redeem
}
